import { createContext, useContext, useEffect, useState } from "react";
import { parsePayload } from "../../util/JWT";
import axiosAPI from '../../util/api/axiosAPI';
import { deleteItem, getItem, setItem } from "../../util/storage";

const AuthContext = createContext();
export const useAuth = () => {
    return useContext(AuthContext);
}

export const AuthProvider = ({ children }) => {
    const [authState, setAuthState] = useState({
        accessToken: null,
        user: null,
        authenticated: false,
    })

    axiosAPI.interceptors.response.use(null, async (error) => {
        const originalRequest = error.config;
        const refreshToken = await getItem('refreshToken');

        if (error?.response && error?.response?.data?.status === 401 && !originalRequest._retry && refreshToken !== null && originalRequest.noReFetch !== true) {
            originalRequest._retry = true;

            return axiosAPI.post('/auth/user/refresh', {
                refreshToken: refreshToken
            }, {
                _retry: true
            }).then((response) => {
                originalRequest.headers.Authorization = `Bearer ${response.data.accessToken}`;
                return axiosAPI(originalRequest);
            }).catch((error) => {
                logout();
                return Promise.reject(error);
            })
        }
        return Promise.reject(error);
    });

    const refresh = async () => {
        return new Promise(async (resolve, reject) => {
            const refreshToken = await getItem('refreshToken');
            if (refreshToken) {
                try {
                    const response = await axiosAPI.post('/auth/user/refresh', {
                        refreshToken: refreshToken
                    });
                    if (response.data.status !== 200) {
                        return {
                            error: true,
                            message: response.data.message,
                        };
                    }
                    let user = parsePayload(response.data.accessToken)
                    // user.role = new Role(user.permission.split(","))

                    // // logout if time is more than > 10:30pm or < 9:30 am
                    // let now = new Date();
                    // let hours = now.getHours();
                    // let minutes = now.getMinutes();
                    // let time = hours + (minutes / 60);
                    // let outOfTime = (time > 22.5) || (time < 9.5)
                    // let outOfTimeBypass = user.role.has(BitField.ADMIN) || user.role.has(BitField.SUPER_ADMIN) || user.role.has(BitField.DASHBOARD_ACCESS_TIME_BYPASS)
                    // let hasDashboardAccess = user.role.has(BitField.DASHBOARD_ACCESS)
                    // let canAccess = hasDashboardAccess && (!outOfTime || outOfTimeBypass)
                    // if (!canAccess) {
                    //     logout();
                    //     return;
                    // }

                    setAuthState({
                        accessToken: response.data.accessToken,
                        user: user,
                        authenticated: true,
                    });
                    axiosAPI.defaults.headers.common['Authorization'] = `Bearer ${response.data.accessToken}`;
                    return resolve(response.data.accessToken);
                } catch (error) {
                    console.log(error)
                    return reject({
                        error: true,
                        message: error.response.data.message,
                    })
                }
            }
        })
    }
    const login = async (username, password) => {
        return new Promise(async (resolve, reject) => {
            try {
                const response = await axiosAPI.post('/auth/user/login', {
                    username: username,
                    password: password
                });

                if (response.data.status !== 200) {
                    return {
                        error: true,
                        message: response.data.message,
                    };
                }
                let user = parsePayload(response.data.accessToken)
                // user.role = new Role(user.permission.split(","))
                // // logout if time is more than > 10:30pm or < 9:30 am
                // let now = new Date();
                // let hours = now.getHours();
                // let minutes = now.getMinutes();
                // let time = hours + (minutes / 60);
                // let outOfTime = (time > 22.5) || (time < 9.5)
                // let outOfTimeBypass = user.role.has(BitField.ADMIN) || user.role.has(BitField.SUPER_ADMIN) || user.role.has(BitField.DASHBOARD_ACCESS_TIME_BYPASS)
                // let hasDashboardAccess = user.role.has(BitField.DASHBOARD_ACCESS)
                // let canAccess = hasDashboardAccess && (!outOfTime || outOfTimeBypass)
                // if (!canAccess) {
                //     logout();
                //     return;
                // }


                setAuthState({
                    accessToken: response.data.accessToken,
                    user: user,
                    authenticated: true,
                });
                axiosAPI.defaults.headers.common['Authorization'] = `Bearer ${response.data.accessToken}`;
                await setItem('refreshToken', response.data.refreshToken);
                return resolve()
            } catch (error) {
                return reject({
                    error: true,
                    message: error.response.data.message,
                })
            }
        })
    }
    const register = async (username, password, passwordConfirm, msisdn, af, bank, bankNumber, firstName, lastName, upline, birthday, ref, pin) => {
        return new Promise(async (resolve, reject) => {
            try {
                const response = await axiosAPI.post('/auth/user/register', {
                    username: username,
                    password: password,
                    passwordConfirm: passwordConfirm,
                    msisdn: msisdn,
                    af: af,
                    bank: bank,
                    bankNumber: bankNumber,
                    firstName: firstName,
                    lastName: lastName,
                    upline: upline,
                    birthday: birthday,
                    ref: ref,
                    pin: pin
                });

                if (response.data.status !== 200) {
                    return {
                        error: true,
                        message: response.data.message,
                    };
                }
                let user = parsePayload(response.data.accessToken)
                // user.role = new Role(user.permission.split(","))
                // // logout if time is more than > 10:30pm or < 9:30 am
                // let now = new Date();
                // let hours = now.getHours();
                // let minutes = now.getMinutes();
                // let time = hours + (minutes / 60);
                // let outOfTime = (time > 22.5) || (time < 9.5)
                // let outOfTimeBypass = user.role.has(BitField.ADMIN) || user.role.has(BitField.SUPER_ADMIN) || user.role.has(BitField.DASHBOARD_ACCESS_TIME_BYPASS)
                // let hasDashboardAccess = user.role.has(BitField.DASHBOARD_ACCESS)
                // let canAccess = hasDashboardAccess && (!outOfTime || outOfTimeBypass)
                // if (!canAccess) {
                //     logout();
                //     return;
                // }


                setAuthState({
                    accessToken: response.data.accessToken,
                    user: user,
                    authenticated: true,
                });
                axiosAPI.defaults.headers.common['Authorization'] = `Bearer ${response.data.accessToken}`;
                await setItem('refreshToken', response.data.refreshToken);
                return resolve()
            } catch (error) {
                return reject({
                    error: true,
                    message: error.response.data.message,
                })
            }
        })
    }

    const logout = async () => {
        return new Promise(async (resolve, reject) => {
            try {
                await axiosAPI.delete('/auth/user/logout', {
                    refreshToken: await getItem('refreshToken'),
                });
                resolve()
            } catch (error) {
                reject({
                    error: true,
                    message: error.response.data.message,
                })
            } finally {
                setAuthState({
                    accessToken: null,
                    user: null,
                    authenticated: false,
                });
                axiosAPI.defaults.headers.common['Authorization'] = '';
                await deleteItem('refreshToken');
                window.location.reload();
            }
        })
    }

    const fetchBalance = () => {
        return new Promise(async (resolve, reject) => {
            try {
                const response = await axiosAPI.get('/wallet/balance', { noReFetch: true });
                if (response.data.status !== 200) {
                    return {
                        error: true,
                        message: response.data.message,
                    };
                }
                return resolve(response.data);
            } catch (error) {
                return reject({
                    error: true,
                    message: error.response.data.message,
                })
            }
        })
    }


    const value = {
        onLogin: login,
        onRegister: register,
        onLogout: logout,
        authState,
        setAuthState,
        axios: axiosAPI,
        fetchBalance,
    };

    useEffect(() => {
        // logout if time is more than > 10:30pm or < 9:30 am
        // let now = new Date();
        // let hours = now.getHours();
        // let minutes = now.getMinutes();
        // let time = hours + (minutes / 60);
        // if (time > 22.5 || time < 9.5 || authState.authenticated) {
        //     return logout();
        // }
        (async () => {
            try {
                await refresh();
            } catch (error) {
                console.error('cant refresh upon loading', error)
            }
        })();
    }, []);



    return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
}