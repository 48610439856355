const banks = [
    {
        "bankCode": "002",
        "bankName": "ธนาคารกรุงเทพ",
        "bankSwift": "BKKBTHBK"
    },
    {
        "bankCode": "006",
        "bankName": "ธนาคารกรุงไทย",
        "bankSwift": "KRTHTHBK"
    },
    {
        "bankCode": "025",
        "bankName": "ธนาคารกรุงศรีอยุธยา",
        "bankSwift": "AYUDTHBK"
    },
    {
        "bankCode": "004",
        "bankName": "ธนาคารกสิกรไทย",
        "bankSwift": "KASITHBK"
    },
    {
        "bankCode": "034",
        "bankName": "ธนาคารเพื่อการเกษตรและสหกรณ์การเกษตร",
        "bankSwift": "BAABTHBK"
    },
    {
        "bankCode": "011",
        "bankName": "ธนาคารทหารไทยธนชาต",
        "bankSwift": "TMBKTHBK"
    },
    {
        "bankCode": "014",
        "bankName": "ธนาคารไทยพาณิชย์",
        "bankSwift": "SICOTHBK"
    },
    {
        "bankCode": "022",
        "bankName": "ธนาคารซีไอเอ็มบีไทย",
        "bankSwift": "UBOBTHBK"
    },
    {
        "bankCode": "024",
        "bankName": "ธนาคารยูโอบี",
        "bankSwift": "UOVBTHBK"
    },
    {
        "bankCode": "030",
        "bankName": "ธนาคารออมสิน",
        "bankSwift": "GSBATHBK"
    },
    {
        "bankCode": "067",
        "bankName": "ธนาคารทิสโก้",
        "bankSwift": "TFPCTHB1"
    },
    {
        "bankCode": "069",
        "bankName": "ธนาคารเกียรตินาคินภัทร",
        "bankSwift": "KIFITHB1"
    },
    {
        "bankCode": "070",
        "bankName": "ธนาคารไอซีบีซี (ไทย)",
        "bankSwift": "ICBKTHBK"
    },
    {
        "bankCode": "071",
        "bankName": "ธนาคารไทยเครดิต",
        "bankSwift": "THCETHB1"
    },
    {
        "bankCode": "073",
        "bankName": "ธนาคารแลนด์ แอนด์ เฮ้าส์",
        "bankSwift": "LAHRTHB1"
    },
    {
        "bankCode": "066",
        "bankName": "ธนาคารอิสลามแห่งประเทศไทย",
        "bankSwift": "TIBTTHBK"
    },
    {
        "bankCode": "033",
        "bankName": "ธนาคารอาคารสงเคราะห์",
        "bankSwift": "GHBNTHBK"
    },
    {
        "bankCode": "991",
        "bankName": "True Wallet",
        "bankSwift": "TRUEWALLET"
    }
]

// 002	ธนาคารกรุงเทพ	BKKBTHBK
// 006	ธนาคารกรุงไทย	KRTHTHBK
// 025	ธนาคารกรุงศรีอยุธยา	AYUDTHBK
// 004	ธนาคารกสิกรไทย	KASITHBK
// 034	ธนาคารเพื่อการเกษตรและสหกรณ์การเกษตร	BAABTHBK
// 011	ธนาคารทหารไทยธนชาต	TMBKTHBK
// 014	ธนาคารไทยพาณิชย์	SICOTHBK
// 022	ธนาคารซีไอเอ็มบีไทย	UBOBTHBK
// 024	ธนาคารยูโอบี	UOVBTHBK
// 030	ธนาคารออมสิน	GSBATHBK
// 067	ธนาคารทิสโก้	TFPCTHB1
// 069	ธนาคารเกียรตินาคินภัทร	KIFITHB1
// 070	ธนาคารไอซีบีซี (ไทย)	ICBKTHBK
// 071	ธนาคารไทยเครดิต	THCETHB1
// 073	ธนาคารแลนด์ แอนด์ เฮ้าส์	LAHRTHB1
// 066	ธนาคารอิสลามแห่งประเทศไทย	TIBTTHBK
// 033	ธนาคารอาคารสงเคราะห์	GHBNTHBK
export default banks;