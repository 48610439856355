import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../components/context/AuthContext';
function Home() {
    const { authState, onLogout, fetchBalance } = useAuth();
    const navigate = useNavigate();

    const authenticated = authState.authenticated;
    const auth = JSON.stringify(authState.user, null, 2)

    async function getBalance(){
        try{
            let result = await fetchBalance();
            alert('fetchBalance result: ' + result.data);
        } catch (error) {
            alert('fetchBalance error: ' + error.message);
        }
        
    }

    

    return authenticated && (
        <div className='w-full h-full flex justify-center items-center'>
            <div className="max-w-lg p-6 bg-white rounded-lg shadow-lg">
                <p>welcome {authState.user.username}</p>
                <p>เบอร์ {authState.user.msisdn}</p>
                <p>bank {authState.user.bank}</p>
                <p>bankNumber {authState.user.bankNumber}</p>
                <p>firstName {authState.user.firstName}</p>
                <p>lastName {authState.user.lastName}</p>
                <p>birthday {authState.user.birthday}</p>
                <p>status {authState.user.status}</p>
                <p>createAt {authState.user.createAt}</p>
                <p>usergroup #{authState.user.userGroup.id} {authState.user.userGroup.name} {authState.user.userGroup.displayName}</p>
                <p>bankPool #{authState.user.userGroup.bankPool.id} {authState.user.userGroup.bankPool.name}</p>
                <div className='m-2 p-2 bg-slate-200 rounded-xl'>
                    <button onClick={() => getBalance()}>ตรวจ balance</button>
                </div>
                <div className='m-2 p-2 bg-slate-200 rounded-xl'>
                    <button onClick={() => onLogout()}>Logout</button>
                </div>
            </div>
        </div >
    )
}

export default Home