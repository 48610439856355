import React, { useEffect } from 'react';
import { Navigate, Route, Routes, useNavigate, useLocation } from 'react-router-dom';
import Login from './page/Auth/Login';
import Logout from './page/Auth/Logout';
import Register from './page/Auth/Register';
// import Dashboard from './page/Dashboard/Dashboard';
// import Deposit from './page/Finance/Deposit';
// import DepositInfo from './page/Finance/DepositInfo';
import Home from './page/Home';
import OTP from './page/Auth/OTP';

function App() {
  const navigate = useNavigate();
  const location = useLocation();
  
  const refreshToken = localStorage.getItem("refreshToken")
  useEffect(() => {
    if (refreshToken === null && !location.pathname.startsWith("/auth")) {
      navigate("/auth/login");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshToken, navigate]);

  return (
    <div className="App w-screen h-screen break-all	">
      <Routes>
        <Route
          path="/"
          element={refreshToken !== null ? <Home /> : <Navigate to="/auth/login" />}
        />
        {/* <Route
          path="/dashboard"
          element={
            validAuth ? (
              <Dashboard />
            ) : (
              <Navigate to="/" />
            )
          }
        />
        <Route
          path="/finance/deposit"
          element={
            validAuth ? (
              <Deposit />
            ) : (
              <Navigate to="/" />
            )
          }
        />
        <Route
          path="/finance/deposit/info/:id"
          element={
            validAuth ? (
              <DepositInfo />
            ) : (
              <Navigate to="/" />
            )
          }
        /> */}
        <Route
          path="/auth/login"
          element={<Login />}
        />
        <Route
          path="/auth/otp"
          element={<OTP />}
        />
        <Route
          path="/auth/register"
          element={<Register />}
        />
        <Route
          path="/auth/logout"
          element={<Logout />}
        />
      </Routes>
    </div>
  );
}

export default App;