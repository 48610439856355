import React, { useState } from 'react';
import { CiUser } from "react-icons/ci";
import { FaRegEye, FaRegEyeSlash } from "react-icons/fa";
import { PiKeyThin } from "react-icons/pi";
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../components/context/AuthContext';

function Login() {
    const {onLogin} = useAuth();
    const navigate = useNavigate();

    const [inputs, setInput] = useState({
        username: "",
        password: "",
    });

    const handleInput = (input, type) => {
        setInput(prevState => ({
            ...prevState,
            [type]: input
        }));
    };

    const login = async () => {
        if (inputs.username && inputs.password) {
            try {
                const result = await onLogin(inputs.username, inputs.password);
                if (result?.error) {
                    return alert(result.message)
                }
                return navigate("/");
            } catch (error) {
                return alert(error.message);
            }
        }
        return alert("กรุณากรอกข้อมูลให้ถูกต้อง")
    }

    const [showPassword, setShowPassword] = useState(false);



    return (
        <div className='w-full h-full flex justify-center items-center'>
            <div className='w-full max-w-[22rem] lg:max-w-[30rem] md:max-w-[30rem] py-16 border rounded-md items-center'
                style={{ backgroundColor: "var(--lightWhite)" }}
            >
                <div className='w-full font-bold text-[2rem] text-center'
                    style={{ color: " #8C3795" }}>
                    <p>LOGIN</p>
                </div>
                {/* username */}
                <div className='flex flex-col px-12 gap-5'>
                    <div className='flex flex-col text-[#8C3795] gap-2'>
                        <div className='flex pb-1 items-center' >
                            <CiUser size={18} />
                            <label htmlFor="username" className=' text-left text-sm'>username</label>
                        </div>
                        <div className=' w-full'>
                            <input type="text" placeholder='your username'
                                onChange={(e) => {
                                    handleInput(e.target.value, 'username');
                                }}
                                style={{ color: "var(--secondary)" }}
                                className='placeholder:text-[1rem]  text-[1.2rem] pl-4 h-[3rem] w-full outline-none rounded-md bg-white border focus:outline-[#8C3795] focus:border-none'
                            >
                            </input>
                        </div>
                    </div>
                    {/* Password */}
                    <div className='flex flex-col text-[#8C3795] gap-2'>
                        <div className='flex pb-1 items-center' >
                            <PiKeyThin size={20} className=' pt-1' />
                            <label htmlFor="username" className='text-left text-sm'>password</label>
                        </div>
                        <div className=' w-full'>
                            <div className=' relative'>
                                <div>
                                    <input type={showPassword ? "text" : "password"} placeholder='**************'
                                        onChange={(e) => {
                                            handleInput(e.target.value, 'password');
                                        }}
                                        style={{ color: "var(--secondary)" }}
                                        className='placeholder:text-[1rem] text-[1.2rem] pl-4 h-[3rem] w-full outline-none rounded-md bg-white border focus:outline-[#8C3795] focus:border-none'
                                    >
                                    </input>
                                </div>
                                <div className=' absolute right-3 top-[14px] '>
                                    <FaRegEyeSlash onClick={() => setShowPassword(!showPassword)} size={20} className={`cursor-pointer ${showPassword ? "hidden" : "block"}`} />
                                    <FaRegEye onClick={() => setShowPassword(!showPassword)} size={20} className={`cursor-pointer ${showPassword ? "block" : "hidden"}`} />
                                </div>
                            </div>
                        </div>
                    </div>
                    <button className='flex items-start text-start' onClick={() => navigate("/auth/otp")}>
                        Register
                    </button>
                    <div className='flex bg-[#8C3795] rounded-lg mt-3 hover:bg-[#8c3795e1] text-white'>
                        <button className=' border w-full h-[4rem] text-[1rem]' onClick={() => login()}>Login</button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Login