import React, { useState } from 'react';
import { CiUser } from "react-icons/ci";
import { FaRegEye, FaRegEyeSlash } from "react-icons/fa";
import { PiKeyThin } from "react-icons/pi";
import { useLocation, useNavigate, Navigate } from 'react-router-dom';
import { useAuth } from '../../components/context/AuthContext';
import banks from '../../util/banks';

export default function Register() {
    const location = useLocation();

    const { onRegister } = useAuth();
    const navigate = useNavigate();
    const [inputs, setInput] = useState({
        username: "",
        password: "",
        passwordConfirm: "",
        msisdn: location.state.msisdn,
        af: null,
        bank: banks[0].bankSwift,
        bankNumber: "",
        firstName: "",
        lastName: "",
        upline: null,
        birthday: "",
        ref: location.state.ref,
        pin: location.state.pin,
    });
    console.log(inputs, location.state)
    const handleInput = (input, type) => {
        setInput(prevState => ({
            ...prevState,
            [type]: input
        }));
    };

    const register = async () => {
        const { username, password, passwordConfirm, msisdn, af, bank, bankNumber, firstName, lastName, upline, birthday, ref, pin } = inputs;
        if (username && password && passwordConfirm && msisdn && bank && bankNumber && firstName && lastName && birthday) {
            try {
                const result = await onRegister(username, password, passwordConfirm, msisdn, af, bank, bankNumber, firstName, lastName, upline, birthday, ref, pin);
                if (result?.error) {
                    console.log(2322222)
                    return alert(result.message)
                }
                return navigate("/");
            } catch (error) {
                console.log(3333333)
                return alert(error.message);
            }
        }
        console.log(123123123, username,password,passwordConfirm,msisdn,bank,bankNumber,firstName,lastName,birthday)
        return alert("กรุณากรอกข้อมูลให้ถูกต้อง")
    }

    const [showPassword, setShowPassword] = useState(false);
    if (!location.state || !location.state.msisdn || !location.state.ref || !location.state.pin) {
        return <Navigate to="/auth/otp" />;
    }

    return (
        <div className='w-full h-full flex justify-center items-center'>
            <div className='w-full max-w-[22rem] lg:max-w-[30rem] md:max-w-[30rem] py-8 border rounded-md items-center'
                style={{ backgroundColor: "var(--lightWhite)" }}
            >
                <div className='w-full font-bold text-[2rem] text-center'
                    style={{ color: " #8C3795" }}>
                    <p>REGISTER</p>
                </div>
                <div className='flex flex-col px-12 gap-1'>
                    {/* username */}
                    <div className='flex flex-col text-[#8C3795] gap-1'>
                        <div className='flex pb-1 items-center' >
                            <CiUser size={18} />
                            <label htmlFor="username" className=' text-left text-sm'>username</label>
                        </div>
                        <div className=' w-full'>
                            <input type="text" placeholder='your username'
                                onChange={(e) => {
                                    handleInput(e.target.value, 'username');
                                }}
                                style={{ color: "var(--secondary)" }}
                                className='placeholder:text-[1rem]  text-[1.2rem] pl-4 h-[3rem] w-full outline-none rounded-md bg-white border focus:outline-[#8C3795] focus:border-none'
                            >
                            </input>
                        </div>
                    </div>
                    {/* Password */}
                    <div className='flex flex-col text-[#8C3795] gap-1'>
                        <div className='flex pb-1 items-center' >
                            <PiKeyThin size={20} className=' pt-1' />
                            <label htmlFor="username" className='text-left text-sm'>password</label>
                        </div>
                        <div className=' w-full'>
                            <div className=' relative'>
                                <div>
                                    <input type={showPassword ? "text" : "password"} placeholder='**************'
                                        onChange={(e) => {
                                            handleInput(e.target.value, 'password');
                                        }}
                                        style={{ color: "var(--secondary)" }}
                                        className='placeholder:text-[1rem] text-[1.2rem] pl-4 h-[3rem] w-full outline-none rounded-md bg-white border focus:outline-[#8C3795] focus:border-none'
                                    >
                                    </input>
                                </div>
                                <div className=' absolute right-3 top-[14px] '>
                                    <FaRegEyeSlash onClick={() => setShowPassword(!showPassword)} size={20} className={`cursor-pointer ${showPassword ? "hidden" : "block"}`} />
                                    <FaRegEye onClick={() => setShowPassword(!showPassword)} size={20} className={`cursor-pointer ${showPassword ? "block" : "hidden"}`} />
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* PasswordConfirm */}
                    <div className='flex flex-col text-[#8C3795] gap-1'>
                        <div className='flex pb-1 items-center' >
                            <PiKeyThin size={20} className=' pt-1' />
                            <label htmlFor="username" className='text-left text-sm'>password confirm</label>
                        </div>
                        <div className=' w-full'>
                            <div className=' relative'>
                                <div>
                                    <input type={showPassword ? "text" : "password"} placeholder='**************'
                                        onChange={(e) => {
                                            handleInput(e.target.value, 'passwordConfirm');
                                        }}
                                        style={{ color: "var(--secondary)" }}
                                        className='placeholder:text-[1rem] text-[1.2rem] pl-4 h-[3rem] w-full outline-none rounded-md bg-white border focus:outline-[#8C3795] focus:border-none'
                                    >
                                    </input>
                                </div>
                                <div className=' absolute right-3 top-[14px] '>
                                    <FaRegEyeSlash onClick={() => setShowPassword(!showPassword)} size={20} className={`cursor-pointer ${showPassword ? "hidden" : "block"}`} />
                                    <FaRegEye onClick={() => setShowPassword(!showPassword)} size={20} className={`cursor-pointer ${showPassword ? "block" : "hidden"}`} />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='flex flex-col text-[#8C3795] gap-1'>
                        <div className='flex pb-1 items-center' >
                            <label htmlFor="bank" className=' text-left text-sm'>bank</label>
                        </div>
                        <div className=' w-full'>
                            <select
                                style={{ color: "var(--secondary)" }}
                                className='placeholder:text-[1rem]  text-[1.2rem] pl-4 h-[3rem] w-full outline-none rounded-md bg-white border focus:outline-[#8C3795] focus:border-none text-black'
                            >
                                {banks.map((bank, index) => {
                                    return (
                                        <option
                                            key={index}
                                            value={bank.bankSwift}
                                            onChange={(e) => {
                                                handleInput(e.target.value, 'bank');
                                            }}
                                            style={{ color: "var(--secondary)" }}
                                            className='placeholder:text-[1rem]  text-[1.2rem] pl-4 h-[3rem] w-full outline-none rounded-md bg-white border focus:outline-[#8C3795] focus:border-none'
                                        >
                                            {bank.bankName}
                                        </option>
                                    )
                                })}
                            </select>
                        </div>
                    </div>
                    <div className='flex flex-col text-[#8C3795] gap-1'>
                        <div className='flex pb-1 items-center' >
                            <label htmlFor="bankNumber" className=' text-left text-sm'>bankNumber</label>
                        </div>
                        <div className=' w-full'>
                            <input type="text" placeholder='your bankNumber'
                                onChange={(e) => {
                                    handleInput(e.target.value, 'bankNumber');
                                }}
                                style={{ color: "var(--secondary)" }}
                                className='placeholder:text-[1rem]  text-[1.2rem] pl-4 h-[3rem] w-full outline-none rounded-md bg-white border focus:outline-[#8C3795] focus:border-none'
                            >
                            </input>
                        </div>
                    </div>
                    <div className='flex flex-row gap-4'>
                        <div className=' text-[#8C3795] gap-1 flex-1'>
                            <label htmlFor="firstName" className=' text-left text-sm'>firstName</label>
                            <input type="text" placeholder='your firstName'
                                onChange={(e) => {
                                    handleInput(e.target.value, 'firstName');
                                }}
                                style={{ color: "var(--secondary)" }}
                                className='placeholder:text-[1rem] h-[3rem] pl-[1rem] flex flex-1 outline-none rounded-md bg-white border focus:outline-[#8C3795] focus:border-none'
                            >
                            </input>
                        </div>

                        <div className=' text-[#8C3795] gap-1 flex-1'>
                            <label htmlFor="lastName" className=' text-left text-sm'>lastName</label>
                            <input type="text" placeholder='your lastName'
                                onChange={(e) => {
                                    handleInput(e.target.value, 'lastName');
                                }}
                                style={{ color: "var(--secondary)" }}
                                className='placeholder:text-[1rem] h-[3rem] pl-[1rem] flex flex-1 outline-none rounded-md bg-white border focus:outline-[#8C3795] focus:border-none'
                            >
                            </input>
                        </div>
                    </div>
                    <div className='flex flex-col text-[#8C3795] gap-1'>
                        <div className='flex pb-1 items-center' >
                            <label htmlFor="birthday" className=' text-left text-sm'>birthday</label>
                        </div>
                        <div className=' w-full'>
                            <input type="date" placeholder='your birthday'
                                onChange={(e) => {
                                    handleInput(e.target.value, 'birthday');
                                }}
                                style={{ color: "var(--secondary)" }}
                                className='placeholder:text-[1rem] text-[1.2rem] pl-4 h-[3rem] w-full outline-none rounded-md bg-white border focus:outline-[#8C3795] focus:border-none'
                            >
                            </input>
                        </div>
                    </div>
                    <button className='flex items-start text-start' onClick={() => navigate("/auth/login")}>
                        Login
                    </button>
                    <div className='flex bg-[#8C3795] rounded-lg mt-3 hover:bg-[#8c3795e1] text-white'>
                        <button className=' border w-full h-[4rem] text-[1rem]' onClick={() => register()}>Register</button>
                    </div>
                </div>
            </div>
        </div>
    )
}