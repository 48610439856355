import { useAuth } from '../../components/context/AuthContext';
import { Navigate } from 'react-router-dom';

function Logout() {
    const { onLogout } = useAuth();

    const logoutUser = async () => {
        try {
            await onLogout();
        } catch (error) {
            console.error('Logout error:', error);
        }
    };

    logoutUser();
    return <Navigate to="/auth/login" />;
}

export default Logout;